import { $debug } from "@/Util";
import tokenAxios from "@/components/tokenAxios";
import Route from '@/router/index';
import axios from 'axios';
import cryptoJs from 'crypto-js';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
$debug(process.env)

var vuex = new Vuex.Store({
  strict: debug,
  state: {
    isMobile: false,
    isMinnowtech: false,  // check if Minnowtech Side
    isCountViewAllowed: false, // only allowed selected farms to see count
    user_info: null,
    loadingStatus: 0,
    message: null,
    pond: null,
    pond_list: null,

    text: "",
    color: "",
    timeout: "",
  },
  mutations: {
    // check if Mobile
    isMobile(state, newStatus) {
      state.isMobile = newStatus
    },
    // show/hide loading status
    loadingStatus(state, newStatus) {
      state.loadingStatus = newStatus
    },
    // set user to state & localStorage
    SET_USER(state, data) {
      $debug('SET_USER', data)
      state.message = 'login_success'
      state.user_info = data
      if (state.user_info.level === 2) state.isMinnowtech = true
      if (state.user_info.level === 3) state.isCountViewAllowed = true
      $debug('User State', state)
      localStorage.setItem('user_info', cryptoJs.AES.encrypt(JSON.stringify(data), process.env.VUE_APP_API_KEY))
    },
    // set message
    SET_MESSAGE(state, data) {
      $debug('SET_MESSAGE...', data)
      state.message = data
    },
    // set pond list
    SET_POND_LIST(state, data) {
      $debug('SET_POND_LIST...', data)
      state.pond_list = data
    },
    // get access_token from localStorage
    INSERT_TOKEN(state) {
      try {
        let user_info = localStorage.getItem('user_info')
        if (user_info) {
          const decrypted = cryptoJs.AES.decrypt(user_info, process.env.VUE_APP_API_KEY).toString(cryptoJs.enc.Utf8)
          user_info = JSON.parse(decrypted)
        }
        state.user_info.access_token = user_info.access_token
      } catch (e) {
        $debug(e)
      }
    },
    // clear user refresh
    CLEAR_USER_REFRESH(state) {
      $debug('CLEAR_USER_REFRESH...')
      state.user_info = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_info');
      $debug('CLEAR_USER_REFRESH...', state.user_info)
      document.location = '/login';
    },
    // set user refresh
    SET_USER_REFRESH(state, data) {
      state.user_info = data
    },
    // logout
    logout(state) {
      state.user_info = null
      localStorage.removeItem('user_info')
      $debug('state.user_info', state.user_info)
      $debug('logout?' + localStorage.getItem('user_info'))
      location.reload()
      // Route.push("/")
    },
    // set pond status
    pond(state, newStatus) {
      state.pond = newStatus
    },
    // set completed status of pond
    completed(state, newStatus) {
      state.pond.completed = newStatus
    },
    // set harvested status of pond
    harveststarted(state, newStatus) {
      state.pond.harveststarted = newStatus
      $debug('state.pond, harveststarted', state.pond, newStatus, state.pond.harveststarted)
    },
    // set harvested status of pond
    harvested(state, newStatus) {
      state.pond.harvested = newStatus
    },
    // set average weight status of pond
    avgweight(state, newStatus) {
      state.pond.avgweight = newStatus
    },
    // set average weight status of pond
    avgweightobs(state, newStatus) {
      state.pond.avgweightobs = newStatus
    }
  },
  actions: {
    login({ commit }, payload) {
      commit("loadingStatus", 1)
      try {
        axios
          .post(process.env.VUE_APP_API_SERVER + '/accounts/login/', payload)
          .then(Response => {
            $debug('Login Success', Response.data)
            if (Response.data.user.id != null) {
              axios.defaults.headers.common.Authorization = `Bearer ${Response.data.access_token}`
              const user = Response.data.user
              user.access_token = Response.data.access_token
              user.refresh_token = Response.data.refresh_token
              commit('SET_USER', user)
              setTimeout(() => Route.push('/ponds'), 100)
            } else {
              commit('SET_MESSAGE', 'login_wrong')
            }
          })
          .catch((e) => {
            $debug('login Error', e)
            commit('SET_MESSAGE', 'login_fail')
          })
          .finally(() => commit('loadingStatus', false))
      } catch (error) {
        console.error('Login Error:', error);
        commit('SET_MESSAGE', 'login_fail');
        commit('loadingStatus', false);
      }
    },
    // refresh token
    // UnpackToken({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('refresh_token')}`
    //     axios.post(API_SERVER + '/api/token/refresh/')
    //       .then(Response => {
    //         $debug(Response.data)
    //         commit('SET_USER_REFRESH', Response.data)
    //       })
    //       .catch(Error => {
    //         $debug(Error)
    //         $debug('unpackToken_error')
    //       })
    //   })
    // },
    // list ponds
    async listPonds({ commit }) {
      try {
        $debug("API_SERVER", this.API_SERVER)
        this.$store.commit("loadingStatus", 1);
        const response = await tokenAxios().get(
          this.API_SERVER + `/farm/pond/?format=json`
        );
        $debug("get project lists", response);

        if (response.status === 200 && response.data.results.length) {
          commit('SET_POND_LIST', response.data.results);
        } else {
          $debug("error", response);
        }
      } catch (e) {
        $debug("error", e);
      } finally {
        this.$store.commit("loadingStatus", 0);
      }
    },
  },
  getters: {
    // show user_info
    loggedIn(state) {
      $debug('loggedIn', state.user_info)
      return state.user_info
    },
    // show loading status
    loadingStatus(state) {
      return state.loadingStatus
    },
  },
})

export default vuex
