<template>
<v-app>

  <!-- <v-fade-transition mode="out-in"> -->
    <router-view :key="$route.fullPath"></router-view>
  <!-- </v-fade-transition> -->
  <vtoast ref="vtoast"/>
</v-app>
</template>

<script>
import vtoast from '@/components/VToast'
export default {
  name: "App",
  components:{
    vtoast
  },
  mounted() {
    this.$root.vtoast = this.$refs.vtoast
    console.log('mounted')
  },
  metaInfo: {
    title: "App",
    titleTemplate: "%s | Minnowtech",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>

<style lang="sass">
.extra-padding
  padding-bottom: 96px !important
  padding-top: 96px !important

  @media screen and (max-width: 959px)
    padding-top: 48px !important
    padding-bottom: 48px !important
</style>
<style>
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #cde2f7;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-image: #46BFC6;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
body {
  overflow: hidden;
}
th {
  background-color: #46BFC6;
  color: #fff!important;
}
.v-application .v-application--wrap .primary {
  background-color: #46BFC6!important; 
  border-color: #46BFC6!important; 
  color: #FFFFFF;
}
.v-application a {
  color: inherit!important;;
}
.v-main .row {
  margin: -12px 0;
}
.v-main {
  height: 100% 
}
a {
  color: inherit;
  text-decoration: none;
  border-bottom: 0;
}
a:visited {
  color: inherit;
}
.pointer {
  cursor: pointer;
}
.block {
  display: block!important;
}
.v-pagination__navigation--disabled {
    display: none!important;
}
@media screen and (max-width: 500px) {
  .v-main.bg>div {
    padding: 10px 10px!important;
  }
  .v-btn, .v-btn__content {
    font-size: 10px!important;
  }
}
.scroll {
  overflow: scroll;
}
.v-application .input-yellow, v-application .input-yellow.v-text-field--solo .v-input__control {
  max-width: 105px;
  min-height: 30px;
  border-radius: 17px;  
  color: #FFFFFF
}

.v-application .input-yellow.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow:  0 0 0;
  background: #46BFC6;
  color: #FFFFFF
}
.v-application .input-yellow.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  margin-top: 9px;
}
.v-application .input-yellow input {
  text-align: center;
  max-height: 32px;
  color: #FFFFFF
}
.v-application .input-yellow.v-text-field--enclosed .v-text-field__details {
  display: none;
  color: #FFFFFF
}

.swal2-actions>.swal2-styled.swal2-confirm {
  background-color: #46BFC6;
}
</style>
