// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import {$debug} from "@/Util";
import cryptoJs from 'crypto-js';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Main.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/account/Login'),
        },
        {
          path: '/mypage',
          component: () => import('@/views/account/MyPage'),
          meta: { requiresAuth: true },
        },
        {
          path: '/ponds',
          name: 'ponds',
          component: () => import('@/views/home/Pond.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/updatepond/:updateid',
          name: 'updatepond',
          component: () => import('@/views/home/Pond.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/farmersamples',
          name: 'farmersamples',
          component: () => import('@/views/home/FarmerSamples.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/farmerreport',
          name: 'farmerreport',
          component: () => import('@/views/home/FarmerReport.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/farmer_report_new',
          name: 'farmer_report_new',
          component: () => import('@/views/home/FarmerReportNew.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/samples',
          name: 'samples',
          component: () => import('@/views/home/Samples.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/devices',
          name: 'devices',
          component: () => import('@/views/home/Devices.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/scan',
          name: 'scan',
          component: () => import('@/views/home/Scan.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/scandata',
          name: 'scan',
          component: () => import('@/views/home/ScanData.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/howto',
          name: 'scan',
          component: () => import('@/views/home/HowTo.vue'),
          meta: { requiresAuth: true },
        },
      ],
    },
  ],
})

// page Authentication check for meta: "{ requiresAuth: true }" pages
router.beforeEach((to, from, next) => {
  // get user_info from 'localStorage'
  let user_info = localStorage.getItem('user_info')
  if (user_info) {
    const decrypted = cryptoJs.AES.decrypt(user_info, process.env.VUE_APP_API_KEY).toString(cryptoJs.enc.Utf8)
    user_info = JSON.parse(decrypted)
  }
  $debug('router.beforeEach: 1) localStorage', user_info, '2) Store', store.getters.loggedIn)

  // set user_info from 'localStorage' to 'store'
  if (user_info && !store.getters.loggedIn) {
    $debug('Commit to SET_USER', user_info)
    store.commit('SET_USER', user_info)
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  $debug('to auth check', to.path, 'Auth needed', requiresAuth, user_info)
  // login page
  if (to.path === '/login') {
    if (!user_info) {
      $debug('2-1. login')
      next()
    } else {
      $debug('2-2. logged', '=> /ponds')
      // next('/project')
    }
    // other pages
  } else {
    if (requiresAuth && !user_info) {
      $debug('2-3. login')
      next('login')
    } else {
      $debug('2-4. logged', to)
      next()
    }

  }
})

export default router
