import axios from 'axios';
import store from '@/store'
import {$debug} from "@/Util";

const tokenAxios = (token) => {
  const axiosInstance = axios.create();

  // request interceptor for adding token
  axiosInstance.interceptors.request.use((config) => {
    // add token to request headers
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  });

  axiosInstance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    $debug('intercept.........', error.response.data.code);

    //catches if the session ended!
    if (error.response.data.code == 'token_not_valid') {
      $debug("EXPIRED TOKEN!");
      // sessionStorage.removeItem('access_token');
      // sessionStorage.removeItem('user');
      store.commit('CLEAR_USER_REFRESH')


    }
    return Promise.reject(error);
  });

  return axiosInstance;
};

export default tokenAxios;