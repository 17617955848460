export default{
    install(Vue){
        // Debug only in development modenn
        Vue.prototype.$debug = $debug
    }
}

export const $debug = function (...obj) {
    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        const err = getErrorObject();
        if (err?.length > 3) {
            let caller_line = err.stack.split("\n")[3];
            if (caller_line) {
                caller_line = caller_line.substring(caller_line.lastIndexOf("/") + 1);
                const arr = caller_line.match(/([a-zA-Z.]*).*:(.*):/, "gi");
                console.log("[" + (arr?.[1] ?? '') + ":" + (arr?.[2] ?? '') + "]", ...(obj ?? {}));
            } else {
                console.error("Unable to parse caller line from stack trace.");
            }
        } else {
            console.error("Error object is undefined or null.");
        }
    }
}

function getErrorObject() {
    try { throw Error('') } catch (err) { return err; }
}
