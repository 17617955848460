import lodash from 'lodash'
import moment from 'moment'
import Vue from 'vue'
import VueLodash from 'vue-lodash'
import App from './App.vue'
import Util from './Util'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/index.js'

Vue.config.productionTip = false

Vue.use(Util)
Vue.use(VueLodash, { name: 'custom', lodash: lodash })

Vue.filter('formatComma', function (value) {
  try {
    return value.toLocaleString()
  } catch (e) {
    return value
  }
})

Vue.filter('formatDate', function (value) {
  try {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY (ddd)') // format
    } else {
      return value
    }
  } catch (e) {
    return value
  }

})

Vue.filter('formatTime', function (value) {
  if (value) {
    return moment(String(value)).format('HH:mm:ss') //  format
  }
})

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')

